export const resetServicePrices = (services = []) => {
  return services.map((i) => {
    i.netPrice = i.grossPrice
    return i
  })
}

export const resetServiceTax = (
  services = [],
  serviceTax = null,
  lookupZipCodeTax = null,
  setNetPrice,
  discount
) => {
  return services.map((i) => {
    if (lookupZipCodeTax) {
      // don't do anything because the taxes are good
    } else if (serviceTax) {
      let rate = parseFloat(serviceTax)
      rate = rate / 100
      let value = i[setNetPrice ? 'netPrice' : 'grossPrice']
      if (discount) {
        const parsedDiscount = (100 - discount) / 100
        value = parseFloat(value * parsedDiscount)
      }
      i.taxes = parseFloat(value * rate)
    }
    return i
  })
}
