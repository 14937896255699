export const getTotalApplications = (services) => {
  return services
}

export const getRemainingApplications = (services) => {
  return services.filter((i) => !i.tooLateToDo)
}

export const getApplicationsLength = (services) => {
  return services.length
}
