import { capitalize } from 'lodash'

export const getInstallmentsLabels = (
  paymentMethod,
  paymentPeriod = 12,
  alternativeLabel = 'Application',
  autoPayAlternativeLabel = null
) => {
  alternativeLabel = alternativeLabel
    .split(' ')
    .map((t) => capitalize(t))
    .join(' ')
  const installmentLabels = {
    switchLabel: `1st ${alternativeLabel}`,
    emailLabel: `${autoPayAlternativeLabel} Pricing`,
    note: ''
  }

  if (paymentMethod === 'monthly-payment') {
    installmentLabels.switchLabel = 'per Month'
    installmentLabels.emailLabel = 'Monthly Billing'

    if (paymentPeriod < 12) {
      installmentLabels.emailLabel = 'Budget Billing'
      installmentLabels.note = `* cost is spread across ${paymentPeriod} months`
    }
  }

  if (
    autoPayAlternativeLabel &&
    autoPayAlternativeLabel !== 'Auto-Pay' &&
    paymentPeriod !== 'monthly-payment'
  ) {
    installmentLabels.emailLabel = `${autoPayAlternativeLabel} Pricing`
  }
  return installmentLabels
}

export const getInstallmentPricing = (
  totalCost,
  paymentMethod,
  paymentPeriod
) => {
  if (paymentMethod === 'monthly-payment') {
    return parseFloat((totalCost / paymentPeriod).toFixed(2))
  } else {
    throw new Error('not monthly pricing')
  }
}
